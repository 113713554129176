@import "variables";
@import "mixins";

.Svg {
    height: 100%;
    width: 50px;
    @include transition(0.4s);

    path {
        fill: $bg-color;
        stroke: $primary-color;
        stroke-width: 4;
        @include transform(scale(0.14));
        @include transition(0.4s);
    }

    &:hover {
        path {
            fill: $primary-color;
        }
    }
}

.Tiny {
    width: 35px;

    path {
        @include transform(scale(0.1));
        @include transition(0.4s);
    }
}

@media (min-width: 768px) {

    .Normal {
        width: 80px;
        @include transition(0.4s);
    
        path {
            @include transform(scale(0.22));
            @include transition(0.4s);
        }
    }
}
