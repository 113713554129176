@import "variables";
@import "mixins";

.NavigationItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;

    a {
        color: $tertiary-color;
        text-decoration: none;
        width: 100%;
        box-sizing: border-box;
        display: block;
        @include transition(all 0.4s ease-in-out);

        &:hover,
        &:active,
        &.active {
            color: $primary-color;
            @include transition(all 0.4s ease-in-out);
        }
    }
}

@media (min-width: 768px) {

    .NavigationItem {
        margin: 0 0.5vw;
        display: flex;
        align-items: center;
        height: 100%;
        width: auto;

        a {
            color: $tertiary-color;
            height: 100%;
            padding: 4px 10px;
            border-bottom: 4px solid transparent;
            @include transition(all 0.4s ease-in-out);

            &:hover,
            &:active,
            &.active {
                border-bottom: 4px solid $primary-color;
            }
        }
    }
}
