@import "variables";
@import "mixins";
@import "shared";

.DrawerToggle {
    width: 40px;
    height: 56px;
    @extend %flex-display;
    flex-flow: column;
    justify-content: space-around;
    padding: 10px 0;
    box-sizing: border-box;
    cursor: pointer;
}

.DrawerToggle div {
    width: 90%;
    height: 3px;
    background-color: $fg-color;
    @include transition(0.4s);
}

.DrawerToggle:hover div {
    background-color: $primary-color;
    @include transition(all 0.2s ease-in-out);
}

.Close.FirstBar {
  @include transform(rotate(-45deg) translate(-9px, 6px));
}

.Close.SecondBar {
    opacity: 0;
}

.Close.ThirdBar {
  @include transform(rotate(45deg) translate(-10px, -8px));
}

@media (min-width: 768px) {
    .DrawerToggle {
        display: none;
    }
}
