@import "variables";

.Author {
    font-size: 0.8rem;

    a {
        color: $primary-color;
        text-decoration: none;

        &:hover {
            border-bottom: 2px solid $primary-color;
        }
    }
}
