
@mixin transition($props...) {
    -webkit-transition: $props;
    transition: $props;
}

@mixin transform($props...) {
    -webkit-transform: $props;
    -ms-transform: $props;
    transform: $props;
}

@mixin transform-origin($props...) {
    -webkit-transform-origin: $props;
    -ms-transform-origin: $props;
    transform-origin: $props;
}

@mixin animation($props...) {
    -webkit-animation: $props;
    animation: $props;
}
