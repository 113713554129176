@import "variables";
@import "mixins";
@import "shared";

.Toolbar {
    position: sticky;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    @include transition(0.4s, transform 0.2s ease-out);

    header {
        width: 100%;
        height: 100%;
        @extend %flex-display;
        justify-content: space-between;
        padding: 0 20px;
        box-sizing: border-box;

        nav {
            padding: 0;
        }
    }
}

.Big {
    @extend %secondary-background;
    height: 60px;
}

.Small {
    @extend %secondary-background;
    height: 45px;
}

.Logo {
    height: 100%;
    padding: 8px;
    padding-left: 0;
}

.DesktopOnly {
    padding: 8px;
    padding-left: 0;
}

@media (max-width: 767px) {
    .DesktopOnly {
        display: none;
    }

    .Big {
        height: 44px;
    }

    .Small {
        height: 44px;
    }
}
