@import "variables";
@import "mixins";
@import "shared";

.Logo {
    color: $fg-color;
    font-weight: bolder;
    height: 100%;
    width: auto;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;

    a {
        text-decoration: none;
        height: 100%;
        width: auto;
        margin-right: 5px;
        @extend %flex-display;
        @include transition(0.4s);

        span {
            margin-left: 5px;
            color: $fg-color;
            font-size: 1.5rem;
            @include transition(0.4s);
    
            &:hover {
                color: $primary-color;
            }
        }
    }

    img {
        height: 100%;
        width: auto;

        &:hover, &:active {
            content: url('../../assets/images/logo_active.png');
        }
    }
}

@media (max-width: 300px) {

    .Logo {
        span {
            display: none;
        }
    }
}

@media (min-width: 768px) {

    .Logo {

        .Small {
            font-size: 1.5rem;
        }

        .Big {
            font-size: 2rem;
        }
    }
}
