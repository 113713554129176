@import "variables";

.CookieConsent {
    background: $bg-color !important;
}

.CookieContent {
    div {
        font-size: 0.8rem !important;
    }
}

.Button {
    button {
        background-color: transparent !important;
        border: 2px solid !important;
        border-radius: 4px !important;
        font-size: 1rem !important;
        opacity: 0.8;

        &:hover, &:active {
            opacity: 1;
        }
    }
}

.Accept {
    color: $primary-color !important;
    border-color: $primary-color !important;
}

.Decline {
    color: $tertiary-color !important;
    border-color: $tertiary-color !important;
    opacity: 0.6;
}

@media (max-width: 767px) {
    .CookieConsent {
        text-align: center;
    }

    .Button {
        margin: auto;

        button{
            font-size: 2rem !important;
        }
    }
}
