@import "variables";
@import "mixins";

.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.Wrapper {
    z-index: 1001;
    position: absolute;
    width: 200px;
    top: 50%;
    left: 50%;
    @include transform(translateX(-50%) translateY(-50%));
}

.Spinner {
    height: 200px;
    width: 100%;
    color: $primary-color;
    @include animation(spinner 1s linear infinite);
}

@keyframes spinner {
    to {
        @include transform(rotate(360deg));
    }
}
