@import "variables";
@import "mixins";
@import "shared";

.SideDrawer {
    position: fixed;
    width: 0;
    height: 100%;
    right: 0;
    top: 45px;
    z-index: 200;
    text-align: center;
    @extend %secondary-background;
    box-sizing: border-box;
    @include transition(0.2s);
}

.SideDrawer.Open {
    width: 100%;
    padding: 0 16px;
    @include transition(0.2s);
}

.SideDrawerNav {
    position: sticky;
    top: 8px;
}

@media (min-width: 768px) {
    .SideDrawer {
        display: none;
    }
}
