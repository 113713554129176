@import "variables";
@import "shared";

.Footer {
    color: $fg-color;
    @extend %secondary-background;
    padding: 1vh;
    text-align: center;
}

.Container {
    margin: auto;
    min-width: 200px;
    max-width: 1000px;
}

.Contact {
    padding: 1vh 0;
}

.LineWrapper {
    margin: auto;
    width: 90%;
    max-width: 1000px;
    padding: 15px 0;
    @extend %flex-display;
    flex-flow: row wrap;
    justify-content: space-around;
}

.Links {
    list-style: none;
    @extend %flex-display;
    flex-flow: row wrap;
    justify-content: space-between;

    li {
        margin: 10px 0.5vw;
        @extend %flex-display;
        height: 100%;
        width: auto;
        cursor: pointer;

        &:hover {
            color: $primary-color;
        }
    }
}
