@import "variables";

.ProgressContainer {
    width: 100%;
    height: 1px;
    background: $tertiary-color;
}

.ProgressBar {
    height: 1px;
    background: $primary-color;
    width: 0;
}